import 'scroll-behavior-polyfill';

/**
***  ページ内リンク  */
(function(){
  const elm = document.querySelectorAll('.js-page-anchor');
  // ない場合はなにもしない
  if( ! elm.length ){ return; };
  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){ return; };
      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      // ない場合はなにもしない
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const position = rect.top + scrollTop;
      //ヘッダーの高さ
      //const header = document.querySelector('.fixed-classname');
      //const heightHeader = header.offsetHeight;
      // スクロール処理
      window.scrollTo({
        //top: position - heightHeader,
        top: position,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
}());

/**
***  modal  */
(function(){
  const trigger = document.querySelectorAll('.js-modal-trigger');
  for ( let i = 0; i < trigger.length; i++) {
    const attr = trigger[i].dataset.modal;
    const wrapper = document.getElementById( attr );
    wrapper.innerHTML = "<div class='modal-inner'><div class='modal-container'><div class='close-btn'></div>" + wrapper.innerHTML + "</div></div>";
    trigger[i].addEventListener( 'click', function(){
      wrapper.classList.add('active');
    });
    wrapper.addEventListener( 'click', function(e){
      const closeBtn = wrapper.querySelector('.close-btn');
      const inner = wrapper.querySelector('.modal-inner');
      const isActive = wrapper.classList.contains( 'active' );
      if( (e.target === inner || e.target === closeBtn) && isActive === true) {
        wrapper.classList.remove( 'active' );
      }
    })
  }
}());

/**
***  Page loading  */
(function(){
  const loadingWrapper = document.getElementById('loadingWrapper');
  if( ! loadingWrapper ){ return; };
  window.onload = function(){
    setTimeout(function() {
      loadingWrapper.classList.add('disabled');
    }, 100);
  }
}());