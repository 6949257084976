import 'scroll-behavior-polyfill';

/**
***  ページ内リンク  */
(function(){
  const elm = document.querySelectorAll('.js-page-anchor');
  // ない場合はなにもしない
  if( ! elm.length ){ return; };
  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){ return; };
      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      // ない場合はなにもしない
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const position = rect.top + scrollTop;
      //ヘッダーの高さ
      //const header = document.querySelector('.fixed-classname');
      //const heightHeader = header.offsetHeight;
      // スクロール処理
      window.scrollTo({
        //top: position - heightHeader,
        top: position,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
}());